























































































import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import FloatPropertyDrawer from './FloatPropertyDrawer.vue';
import QuaternionPropertyDrawer from './QuaternionPropertyDrawer.vue';
import StringPropertyDrawer from './StringPropertyDrawer.vue';
import ArbitraryStructPropertyDrawer from './struct/ArbitraryStructPropertyDrawer.vue';
import BoxStructPropertyDrawer from './struct/BoxStructPropertyDrawer.vue';
import ColorStructPropertyDrawer from './struct/ColorStructPropertyDrawer.vue';
import InventoryItemStructPropertyDrawer from './struct/InventoryItemStructPropertyDrawer.vue';
import LinearColorStructPropertyDrawer from './struct/LinearColorStructPropertyDrawer.vue';
import RailroadTrackPositionStructPropertyDrawer from './struct/RailroadTrackPositionStructPropertyDrawer.vue';
import Vector3PropertyDrawer from './Vector3PropertyDrawer.vue';

@VueComponent({
  components: {
    ArbitraryStructPropertyDrawer,
    BoxStructPropertyDrawer,
    QuaternionPropertyDrawer,
    Vector3PropertyDrawer,
    LinearColorStructPropertyDrawer,
    InventoryItemStructPropertyDrawer,
    ColorStructPropertyDrawer,
    RailroadTrackPositionStructPropertyDrawer,
    StringPropertyDrawer,
    FloatPropertyDrawer
  }
})
export default class StructPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: { [id: string]: any }; // StructProperty.value
}
