














import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@VueComponent({
  components: {
    // because property drawer is recursive: https://stackoverflow.com/a/58875919
    PropertyPropertyDrawer: () => import('../PropertyPropertyDrawer.vue')
  }
})
export default class BoolPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: any;
  @Action('updateObjectValue') updateObjectValue: any;

  change(value: boolean) {
    this.updateObjectValue({
      path: this.path,
      value: value ? 1 : 0
    });
  }
}
