




























































































import { Property } from 'satisfactory-json';
import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import ArrayPropertyDrawer from './ArrayPropertyDrawer.vue';
import BoolPropertyDrawer from './BoolPropertyDrawer.vue';
import BytePropertyDrawer from './BytePropertyDrawer.vue';
import EnumPropertyDrawer from './EnumPropertyDrawer.vue';
import FloatPropertyDrawer from './FloatPropertyDrawer.vue';
import IntPropertyDrawer from './IntPropertyDrawer.vue';
import MapPropertyDrawer from './MapPropertyDrawer.vue';
import ObjectPropertyDrawer from './ObjectPropertyDrawer.vue';
import StringPropertyDrawer from './StringPropertyDrawer.vue';
import StructPropertyDrawer from './StructPropertyDrawer.vue';
import TextPropertyDrawer from './TextPropertyDrawer.vue';

@VueComponent({
  components: {
    IntPropertyDrawer,
    FloatPropertyDrawer,
    ObjectPropertyDrawer,
    BoolPropertyDrawer,
    StringPropertyDrawer,
    StructPropertyDrawer,
    ArrayPropertyDrawer,
    BytePropertyDrawer,
    MapPropertyDrawer,
    TextPropertyDrawer,
    EnumPropertyDrawer
  }
})
export default class PropertyPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: Property;
}
