


























































































import { Actor, Component } from 'satisfactory-json';
import { Component as VueComponent, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BoolPropertyDrawer from '../drawers/BoolPropertyDrawer.vue';
import MultiTranslateDrawer from '../drawers/MultiTranslateDrawer.vue';
import PropertyPropertyDrawer from '../drawers/PropertyPropertyDrawer.vue';
import QuaternionPropertyDrawer from '../drawers/QuaternionPropertyDrawer.vue';
import Vector3PropertyDrawer from '../drawers/Vector3PropertyDrawer.vue';

@VueComponent({
  components: {
    Vector3PropertyDrawer,
    QuaternionPropertyDrawer,
    PropertyPropertyDrawer,
    BoolPropertyDrawer,
    MultiTranslateDrawer
  }
})
export default class PropertiesPanel extends Vue {
  @State(state => state.selectedActors)
  selectedActors!: Actor[];
  @State(state => state.selectedComponents)
  selectedComponents!: Component[];
  @State(state => state.selectedPathNames)
  selectedPathNames!: string[];

  @Action('select')
  select: any;

  // display center point when multiple actors are selected
  center: number[] = [0, 0, 0];

  @Watch('selectedActors', { deep: true }) // deep:true needed to update when the actors are moved using the helper
  onSelectedActorsChange(val: any) {
    if (val.length > 1) {
      this.center = [0, 0, 0];
      val.forEach((actor: Actor) => {
        this.center[0] += actor.transform.translation[0];
        this.center[1] += actor.transform.translation[1];
        this.center[2] += actor.transform.translation[2];
      });
      this.center[0] /= val.length;
      this.center[1] /= val.length;
      this.center[2] /= val.length;
    }
  }

  // Remove the level from the pathname
  formatPathName(pathName: string) {
    return pathName.substring(pathName.indexOf('.') + 1);
  }
}
