























import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@VueComponent({})
export default class BytePropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: number;
  @Action('updateObjectValue') updateObjectValue: any;

  changeEnumName(value: string) {
    this.updateObjectValue({
      path: this.path + '.enumName',
      value: value
    });
  }
  changeValue(value: string) {
    this.updateObjectValue({
      path: this.path + '.value',
      value: parseInt(value)
    });
  }
}
