

































import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ArbitraryStructPropertyDrawer from './ArbitraryStructPropertyDrawer.vue';

@VueComponent({
  components: {
    ArbitraryStructPropertyDrawer
  }
})
export default class InventoryItemStructPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: {
    levelName: string;
    pathName: string;
  };
  @Action('updateObjectValue') updateObjectValue: any;
  changeItemName(value: string) {
    this.updateObjectValue({
      path: this.path + '.itemName',
      value: value
    });
  }
  changeLevelName(value: string) {
    this.updateObjectValue({
      path: this.path + '.levelName',
      value: value
    });
  }
  changePathName(value: string) {
    this.updateObjectValue({
      path: this.path + '.pathName',
      value: value
    });
  }
}
