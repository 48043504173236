

























































































































import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import FloatPropertyDrawer from './FloatPropertyDrawer.vue';
import IntPropertyDrawer from './IntPropertyDrawer.vue';
import ObjectPropertyDrawer from './ObjectPropertyDrawer.vue';
import StringPropertyDrawer from './StringPropertyDrawer.vue';
import LinearColorStructPropertyDrawer from './struct/LinearColorStructPropertyDrawer.vue';
import TextPropertyDrawer from './TextPropertyDrawer.vue';

@VueComponent({
  components: {
    IntPropertyDrawer,
    ObjectPropertyDrawer,
    FloatPropertyDrawer,
    StringPropertyDrawer,
    TextPropertyDrawer,
    LinearColorStructPropertyDrawer,
    PropertyPropertyDrawer: () => import('./PropertyPropertyDrawer.vue')
  }
})
export default class ArrayPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: { [id: string]: any }; // ArrayProperty.value

  isVisible: boolean = false;
  visible: boolean[] = [];

  toggleVisible(index: number) {
    Vue.set(
      this.visible,
      index,
      !(this.visible.length > index && this.visible[index])
    );
  }
}
