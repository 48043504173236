







































































import { reportException } from '@/lib/core/errorReporting';
import { isElectron } from '@lib/isElectron';
import {
  Component as VueComponent,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';
import { mapState } from 'vuex';
import { namespace, State } from 'vuex-class';
import Menubar from '../web/Menubar.vue';
import ClassListPanel from './panels/ClassListPanel.vue';
import JsonPanel from './panels/JsonPanel.vue';
import ObjectListPanel from './panels/ObjectListPanel.vue';
import PropertiesPanel from './panels/PropertiesPanel.vue';
import ScenePanel from './panels/ScenePanel.vue';

const settingsNamespace = namespace('settings');

@VueComponent({
  components: {
    Menubar,
    ScenePanel,
    ObjectListPanel,
    JsonPanel,
    ClassListPanel,
    PropertiesPanel
  },
  computed: {
    ...mapState('settings', ['showPropertiesPanel'])
  }
})
export default class Editor extends Vue {
  @Prop({ default: !isElectron() }) showMenubar!: boolean;
  @State(state => state.settings.layout) layout: any;
  @State(state => state.settings.layoutSettings) layoutSettings: any;
  @State(state => state.settings.showPropertiesPanel)
  showPropertiesPanel!: boolean;

  @settingsNamespace.Action('resetLayout')
  resetLayout: any;
  @settingsNamespace.Action('setPropertiesPanelEnabled')
  setPropertiesPanelEnabled: any;

  // used to remount the golden-layout component after layout reset, see https://stackoverflow.com/a/47466574
  layoutComponentKey: number = 0;

  // Watch layout settings changes and change the corresponding layout only when the editor is active
  @Watch('showPropertiesPanel')
  onPropertiesPanel(value: boolean) {
    if (value != this.layoutSettings.propertiesPanelEnabled) {
      this.setPropertiesPanelEnabled(value);
    }
  }

  onLayoutInitialised() {
    // apply possibly changed settings to layout after it is initalized
    this.onPropertiesPanel(this.showPropertiesPanel);
  }

  changeLayout(layout: any) {
    this.$store.commit('settings/SET_LAYOUT', layout);
  }

  onSceneResize() {
    (this.$refs.playground as any).handleResize();
  }

  onLayoutError() {
    // The layout was not compatible with the current settings
    // Report the current settings state
    console.log(JSON.stringify(this.$store.state.settings));
    reportException('Could not load stored layout');
    this.resetLayout();
    // remount the golden-layout component as the layout state is only read on mount
    this.layoutComponentKey = 1 - this.layoutComponentKey;
  }
}
