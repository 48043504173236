









































import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@VueComponent({})
export default class RailroadTrackPositionStructPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: {
    levelName: string;
    pathName: string;
  };
  @Action('updateObjectValue') updateObjectValue: any;
  changeLevelName(value: string) {
    this.updateObjectValue({
      path: this.path + '.levelName',
      value: value
    });
  }
  changePathName(value: string) {
    this.updateObjectValue({
      path: this.path + '.pathName',
      value: value
    });
  }
  changeOffset(value: string) {
    this.updateObjectValue({
      path: this.path + '.offset',
      value: parseFloat(value)
    });
  }
  changeForward(value: string) {
    this.updateObjectValue({
      path: this.path + '.forward',
      value: parseFloat(value)
    });
  }
}
