var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"R","value":_vm.value.r,"hide-details":"","outlined":"","dense":""},on:{"change":_vm.changeR}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"G","value":_vm.value.g,"hide-details":"","outlined":"","dense":""},on:{"change":_vm.changeG}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"B","value":_vm.value.b,"hide-details":"","outlined":"","dense":""},on:{"change":_vm.changeB}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"A","value":_vm.value.a,"hide-details":"","outlined":"","dense":""},on:{"change":_vm.changeA}})],1),_c('v-col',[_c('div',{staticClass:"preview",style:({
          backgroundColor:
            'rgba(' +
            _vm.value.r +
            ',' +
            _vm.value.g +
            ',' +
            _vm.value.b +
            ',' +
            _vm.value.a / 255 +
            ')'
        })})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }