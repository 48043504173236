
































































import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@VueComponent({})
export default class LinearColorStructPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: any;
  @Prop({ default: false }) named!: boolean;
  @Action('updateObjectValue') updateObjectValue: any;

  changeR(value: string) {
    this.updateObjectValue({
      path: this.path + '.r',
      value: parseFloat(value)
    });
  }

  changeG(value: string) {
    this.updateObjectValue({
      path: this.path + '.g',
      value: parseFloat(value)
    });
  }

  changeB(value: string) {
    this.updateObjectValue({
      path: this.path + '.b',
      value: parseFloat(value)
    });
  }

  changeA(value: string) {
    this.updateObjectValue({
      path: this.path + '.a',
      value: parseFloat(value)
    });
  }
}
