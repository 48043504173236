












import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import BoolPropertyDrawer from '../BoolPropertyDrawer.vue';
import Vector3PropertyDrawer from '../Vector3PropertyDrawer.vue';

@VueComponent({
  components: {
    BoolPropertyDrawer,
    Vector3PropertyDrawer
  }
})
export default class BoxStructPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: boolean;
}
