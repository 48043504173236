








































































import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import IntPropertyDrawer from './IntPropertyDrawer.vue';
import ObjectPropertyDrawer from './ObjectPropertyDrawer.vue';
import StringPropertyDrawer from './StringPropertyDrawer.vue';
import ArbitraryStructPropertyDrawer from './struct/ArbitraryStructPropertyDrawer.vue';

@VueComponent({
  components: {
    ArbitraryStructPropertyDrawer,
    IntPropertyDrawer,
    ObjectPropertyDrawer,
    StringPropertyDrawer
  }
})
export default class MapPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: { [id: string]: any }; // StructProperty.value
  isVisible = false;
  visible: boolean[] = [];

  toggleVisible(index: number) {
    Vue.set(
      this.visible,
      index,
      !(this.visible.length > index && this.visible[index])
    );
  }
}
