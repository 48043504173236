var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toolbar",on:{"mousedown":function($event){$event.stopPropagation();/*prevent deselect when clicking on toolbar button*/}}},[_c('div',{staticClass:"section"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],class:{ active: _vm.translateActive },on:{"mousedown":function($event){$event.stopPropagation();return _vm.$emit('set-translate')}}},on),[_vm._v(_vm._s(_vm.$t('toolbar.translate')))])]}}])},[_c('span',[_vm._v("G")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],class:{ active: _vm.rotateActive },on:{"mousedown":function($event){$event.stopPropagation();return _vm.$emit('set-rotate')}}},on),[_vm._v(_vm._s(_vm.$t('toolbar.rotate')))])]}}])},[_c('span',[_vm._v("R")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],class:{ active: _vm.scaleActive },on:{"mousedown":function($event){$event.stopPropagation();return _vm.$emit('set-scale')}}},on),[_vm._v(_vm._s(_vm.$t('toolbar.scale')))])]}}])},[_c('span',[_vm._v("S")])])],1),_c('div',{staticClass:"section"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],class:{ active: _vm.worldActive },on:{"mousedown":function($event){$event.stopPropagation();return _vm.$emit('set-world')}}},on),[_vm._v(_vm._s(_vm.$t('toolbar.world')))])]}}])},[_c('span',[_vm._v("W")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],class:{ active: _vm.localActive },on:{"mousedown":function($event){$event.stopPropagation();return _vm.$emit('set-local')}}},on),[_vm._v(_vm._s(_vm.$t('toolbar.local')))])]}}])},[_c('span',[_vm._v("L")])])],1),_c('div',{staticClass:"section"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","disabled":_vm.focusDisabled},on:{"mousedown":function($event){$event.stopPropagation();return _vm.focusSelectedObject.apply(null, arguments)}}},on),[_vm._v(_vm._s(_vm.$t('propertyEditor.focusButton')))])]}}])},[_vm._v(" F ")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.snapping)?_c('v-icon',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"icn-btn",on:{"click":function($event){$event.stopPropagation();return _vm.setSnapping(false)}}},on),[_vm._v("mdi-grid")]):_c('v-icon',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"icn-btn",on:{"click":function($event){return _vm.setSnapping(true)}}},on),[_vm._v("mdi-grid-off")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('settings.snapping'))+" ")]),_c('div',{staticClass:"spacer"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"icn-btn",on:{"click":function($event){_vm.showPhotoMode = true}}},on),[_vm._v("mdi-camera")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('photoMode.title'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"bugReportIcon icn-btn",on:{"click":function($event){return _vm.$emit('report-bug')}}},on),[_vm._v("mdi-bug")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('toolbar.reportBug'))+" ")]),_c('PhotoModeDialog',{model:{value:(_vm.showPhotoMode),callback:function ($$v) {_vm.showPhotoMode=$$v},expression:"showPhotoMode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }