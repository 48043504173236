var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.value.type === 'Vector' || _vm.value.type === 'Rotator')?_c('Vector3PropertyDrawer',{attrs:{"path":_vm.path,"value":_vm.value,"named":true}}):(_vm.value.type === 'Box')?_c('BoxStructPropertyDrawer',{attrs:{"path":_vm.path,"value":_vm.value}}):(_vm.value.type === 'Color')?_c('ColorStructPropertyDrawer',{attrs:{"value":_vm.value,"path":_vm.path}}):(_vm.value.type === 'LinearColor')?_c('LinearColorStructPropertyDrawer',{attrs:{"value":_vm.value,"path":_vm.path}}):(_vm.value.type === 'Quat')?_c('QuaternionPropertyDrawer',{attrs:{"path":_vm.path,"value":_vm.value,"named":true}}):(_vm.value.type === 'InventoryItem')?_c('InventoryItemStructPropertyDrawer',{attrs:{"path":_vm.path,"value":_vm.value}}):(_vm.value.type === 'RailroadTrackPosition')?_c('RailroadTrackPositionStructPropertyDrawer',{attrs:{"path":_vm.path,"value":_vm.value}}):(_vm.value.type === 'TimerHandle')?_c('StringPropertyDrawer',{attrs:{"path":_vm.path + '.handle',"value":_vm.value.handle}}):(
      [
        'Transform',
        'RemovedInstanceArray',
        'InventoryStack',
        'ProjectileData',
        'TrainSimulationData',
        'ResearchData',
        'Hotbar'
      ].includes(_vm.value.type)
    )?_c('ArbitraryStructPropertyDrawer',{attrs:{"path":_vm.path,"value":_vm.value}}):(_vm.value.type === 'Guid')?_c('StringPropertyDrawer',{attrs:{"path":_vm.path + '.value',"value":_vm.value.value}}):(_vm.value.type === 'FluidBox')?_c('FloatPropertyDrawer',{attrs:{"path":_vm.path + '.handle',"value":_vm.value.handle}}):_c('div',[_c('div',{staticClass:"err"},[_vm._v("unimplemented struct type "+_vm._s(_vm.value.type))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }