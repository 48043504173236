var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menubar"},[_c('div',{staticClass:"logoContainer",on:{"mouseover":function($event){_vm.logoAnimating = true},"mouseleave":function($event){_vm.logoAnimating = false}}},[_c('Logo',{attrs:{"height":48,"black":"#a0a0a0","animating":_vm.logoAnimating}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 'o']),expression:"['ctrl', 'o']",modifiers:{"once":true}},{name:"ripple",rawName:"v-ripple"}],staticClass:"menu-item",on:{"click":function($event){return _vm.showOpenDialog()},"shortkey":function($event){return _vm.showOpenDialog()}}},on),[_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(_vm.$t('menubar.open'))+" ")],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('keyboard.ctrl'))+"+O ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 's']),expression:"['ctrl', 's']",modifiers:{"once":true}},{name:"ripple",rawName:"v-ripple"}],staticClass:"menu-item",on:{"click":function($event){return _vm.showSaveDialog()},"shortkey":function($event){return _vm.showSaveDialog()}}},on),[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t('menubar.save'))+" ")],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('keyboard.ctrl'))+"+S ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['f1']),expression:"['f1']",modifiers:{"once":true}},{name:"ripple",rawName:"v-ripple"}],staticClass:"menu-item",on:{"click":function($event){return _vm.showHelpDialog()},"shortkey":function($event){return _vm.showHelpDialog()}}},on),[_c('v-icon',[_vm._v("mdi-help-circle")]),_vm._v(" "+_vm._s(_vm.$t('menubar.help'))+" ")],1)]}}])},[_vm._v(" F1 ")]),_c('span',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"menu-item",on:{"click":function($event){return _vm.showSettingsDialog()}}},[_c('v-icon',[_vm._v("mdi-cog")]),_vm._v(" "+_vm._s(_vm.$t('menubar.settings'))+" ")],1),_c('span',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 'z']),expression:"['ctrl', 'z']",modifiers:{"once":true}}],on:{"shortkey":_vm.undo}}),_c('span',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 'shift', 'z']),expression:"['ctrl', 'shift', 'z']",modifiers:{"once":true}}],on:{"shortkey":_vm.redo}}),_c('div',{staticClass:"spacer"}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-menu")]),_vm._v(" "+_vm._s(_vm.$t('menubar.more'))+" ")],1)]}}])},[_c('v-list',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"disabled":_vm.undoDisabled},on:{"click":_vm.undo}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-undo")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.undo')))])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('keyboard.ctrl'))+"+Z ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"disabled":_vm.redoDisabled},on:{"click":_vm.redo}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-redo")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.redo')))])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('keyboard.ctrl'))+"+"+_vm._s(_vm.$t('keyboard.shift'))+"+Z ")]),_c('v-list-item',{on:{"click":function($event){return _vm.showOpenJsonDialog()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-upload")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.importJson')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showSaveJsonDialog()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.exportJson')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openGithub()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-github")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.github')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showLicensesDialog()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-headline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.openSource')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showAboutDialog()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menubar.about')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }