























import { DIALOG_SETTINGS } from '@lib/constants';
import { EventBus } from '@lib/event-bus';
import { Component as VueComponent, Vue } from 'vue-property-decorator';
import CenterWhiteBox from '../core/CenterWhiteBox.vue';
import Logo from '../core/Logo.vue';
import OpenBox from './OpenBox.vue';

@VueComponent({
  components: {
    Logo,
    OpenBox,
    CenterWhiteBox
  }
})
export default class OpenView extends Vue {
  logoAnimating: boolean = false;
  showSettingsDialog() {
    EventBus.$emit(DIALOG_SETTINGS);
  }
}
