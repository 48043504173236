












import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@VueComponent({})
export default class BoolPropertyDrawer extends Vue {
  @Prop() path!: string;
  @Prop() value!: boolean;
  @Action('updateObjectValue') updateObjectValue: any;

  change(value: boolean) {
    this.updateObjectValue({
      path: this.path,
      value: value ? 1 : 0
    });
  }
}
